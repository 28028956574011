import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { CopyObject } from "../../utils/common";
import EventField from "./field";

const EventNew = (props: any) => {

  const [items, setItems] = useState<any[]>(props.items?props.items:[]);
  const [steps, setSteps] = useState<any[]>([]);

  const [currentStepNum, setCurrentStepNum] = useState<number>(1);
  const [currentStep, setCurrentStep] = useState<any>({});

  const clear = () => {
    setCurrentStep({});
    setItems([]);
    setItems(props.items?props.items:[]);
  }

  const initStep = (index: number) => {
    if(steps[index]) {
      let stepsObj: any = {};
      Object.values(steps).forEach((step: any) => {
        stepsObj[step.item.name] = CopyObject(step);
      });
      setCurrentStep(stepsObj);
    }
    else {
      setCurrentStep({});
    }
    setCurrentStepNum(index+1);
  }

  return (
    <Modal size="xl" show={props.show?true:false} onHide={() => props.onClose()}>
      <ModalHeader closeButton={true}>
        <ModalTitle>
          Combination
        </ModalTitle>
      </ModalHeader>
      <div className="form-group" style={{ backgroundColor: '#eee', padding: '0px 10px' }}>
        &nbsp;
        {steps.map((step: any, index: number) => {
          return (
            <button
            className={(currentStepNum===index+1)?'btn btn-info my-btn':'btn btn-primary my-btn'}
              onClick={() => setCurrentStepNum(index+1)}
              style={{ margin: '5px' }}>Step {index+1}</button>
          );
        })}
        <button
          className={(currentStepNum===steps.length+1)?'btn btn-info my-btn':'btn btn-primary my-btn'}
          onClick={() => setCurrentStepNum(steps.length + 1)}
          style={{ margin: '5px' }}>Current Step</button>
      </div>
      <ModalBody>
        <div className="form-group" style={{maxHeight:'600px', overflow:'auto'}}>
          {items.map((item: any) => {
            return (
              <EventField
                step={currentStepNum}
                heading={item.name}
                items={item.items}
                onChange={(changes: any[]) => {
                  let comb: any = {...currentStep};
                  if(changes.length>0) {
                    comb[item.name] = {
                      item: {
                        name: item.name
                      },
                      selected: changes
                    }
                  }
                  else {
                    delete comb[item.name];
                  }
                  setCurrentStep(comb);
                }}
              />
            );
          })}
        </div>
      </ModalBody>
      {(Object.keys(currentStep).length>0 && currentStepNum===steps.length + 1)?(
        <div className="form-group text-center" style={{borderTop:'1px solid #e3e6f0', paddingTop:'10px'}}>
          <button className="btn btn-primary my-btn" onClick={() => {
            let stepsValues : any[] = steps;
            stepsValues.push(CopyObject(Object.values(currentStep)));
            setSteps(stepsValues);
            clear();
            setCurrentStepNum(steps.length + 1);
          }}>
            <i className="fa fa-plus" /> &nbsp;
            Add New ?
          </button>          
        </div>
      ):<></>}
      <ModalFooter>
        <button className="btn btn-success my-btn float-right" onClick={() => {
            console.log(steps);
          }}>
          Submit <i className="fa fa-arrow-right" />
        </button>
      </ModalFooter>
    </Modal>
  );
};
export default EventNew;