import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { SessionUtil } from '../../utils/session';
import { LoginServiceHandler } from './service';

const LoginPage = () => {

  const [inputUsername, setInputUsername] = useState<string>('');
  const [inputPassword, setInputPassword] = useState<string>('');
  const [status, setStatus] = useState<any>(<></>);

  const handler = async (event: any) => {
    event?.preventDefault();
    if(inputUsername.trim()==='' || inputPassword.trim()==='') {
      goStatus('danger', 'Please fill the value');
      return;
    } 
    goStatus('warning', 'Checking Login ...', false);
    let res: any = await LoginServiceHandler(inputUsername, inputPassword);
    console.log(res);
    if(res.meta) {
      if(res.meta.status===0) {
        goStatus('success', 'Login Successfull ..', false);
        SessionUtil.set({
          'id': res.data.id,
          'hash': res.data.hash,
          'username': res.data.username,
          'firstname': res.data.firstname,
          'lastname': res.data.lastname,
        });
        window.location.reload();
      }
      else {
        goStatus('danger', res.meta.message);
      }
    }
    else {
      goStatus('danger', 'Could not login');
    }
  };

  const goStatus = (type: string, message: string, autoHide: boolean = true) => {
    setStatus(<Alert variant={type}>{message}</Alert>);
    if(autoHide) {
      setTimeout(() => {
        setStatus(<></>);
      }, 3000);
    }
  }
  
  return (
    <div>
      <Helmet bodyAttributes={{class: 'bg-gradient-primary'}}>
        <title>Login - Nirwanji</title>
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-xl-3 col-lg-3 col-md-6">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        Login - Nirwanji
                      </h1>
                    </div>
                    <form className="user" onSubmit={handler}>
                      <div className="form-group">
                        <input type="text" className="form-control form-control-user" onChange={(e:any) => setInputUsername(e.target.value)} placeholder="Enter Username" />
                      </div>
                      <div className="form-group">
                        <input type="password" className="form-control form-control-user" onChange={(e:any) => setInputPassword(e.target.value)} placeholder="Enter Password" />
                      </div>
                      {status}
                      <button type="submit" className="btn btn-primary btn-user btn-block" onClick={handler}>
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};
export default LoginPage;