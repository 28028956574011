import GridRendererAction from "./render/action";

const GridColumns = (columns: any[], config: any) => {
  let allColumns: any[] = [];
  let cellStyle = {'padding': '5px', 'height': '30px', 'line-height': '15px'};
  allColumns.push({
    headerName: 'Id',
    field: 'id',
    filter: false,
    editable: false,
    width: 80,
    suppressSizeToFit: true,
    cellStyle: cellStyle
  });
  columns.forEach(column => {
    column.cellStyle = cellStyle
    allColumns.push(column)
  });
  let action: any = {
    headerName: "Action",
    colId: "action",
    cellRenderer: GridRendererAction,
    editable: false,
    filter: false,
    width: 150,
    suppressSizeToFit: true,
    pinned: "right",
    lockPinned: true,
    cellClass: "lock-pinned",
    sortable: false,
    cellStyle: cellStyle
  };
  if(config.action) {
    if(config.action.cellRenderer) action.cellRenderer = config.action.cellRenderer;
    if(config.action.width) action.width = config.action.width;
  }
  allColumns.push(action);
  return allColumns;
};
export default GridColumns;