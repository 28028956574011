import { useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import LanguageField from "../../components/language";
import { SessionUtil } from "../../utils/session";

const Dashboard = (props: any) => {

  const Sidebar = () => {
    let logoStyle = {
      width:"38px",
      backgroundColor: "#fff",
      padding: "2px",
      borderRadius:"5px",
      display:"inline-block"
    };
    return (
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard">
          <div className="sidebar-brand-icon rotate-n-15_">
            <span style={logoStyle}><img src="/logo512.png" alt="Logo" style={{width:"100%"}} /></span>
          </div>
          <div className="sidebar-brand-text mx-3">Nirwanji</div>
        </Link>
        <hr className="sidebar-divider my-0" />

        <li className="nav-item">
          <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#ImportExport" aria-controls="ImportExport">
            <i className="fas fa-fw fa-plus"></i>
            <span>Import &amp; Export</span>
          </a>
          <div id="ImportExport" className="collapse">
            <div className="bg-white py-2 collapse-inner rounded">
              <Link className="collapse-item" to="/import">Import</Link>
              <Link className="collapse-item" to="/export">Export</Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#ephemeris" aria-controls="ephemeris">
            <i className="fas fa-fw fa-plus"></i>
            <span>Ephemeris</span>
          </a>
          <div id="ephemeris" className="collapse">
            <div className="bg-white py-2 collapse-inner rounded">
              <Link className="collapse-item" to="/ephemeris/step-1">Step 1</Link>
              <Link className="collapse-item" to="/ephemeris/step-2">Step 2</Link>
              <Link className="collapse-item" to="/ephemeris/step-3">Step 3</Link>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/item">
            <i className="fa fa-user"></i>
            <span>Item &amp; Property</span>
          </Link>
        </li>
        <li className="nav-item">
          <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#make" aria-controls="make">
            <i className="fa fa-globe"></i>
            <span>Make</span>
          </a>
          <div id="make" className="collapse">
            <div className="bg-white py-2 collapse-inner rounded">
              <Link className="collapse-item" to="/make/categories">Categories</Link>
              <Link className="collapse-item" to="/make/event">Event</Link>
              <Link className="collapse-item" to="/make/event/desc">Event - Description</Link>
              <Link className="collapse-item" to="/make/event/prediction">Event - Prediction</Link>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/template">
            <i className="fa fa-user"></i>
            <span>Template</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/compile">
            <i className="fa fa-user"></i>
            <span>Compile</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/generate">
            <i className="fa fa-user"></i>
            <span>Generate</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/validate">
            <i className="fa fa-user"></i>
            <span>Validate</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/view">
            <i className="fa fa-user"></i>
            <span>View</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/compare">
            <i className="fa fa-user"></i>
            <span>Compare</span>
          </Link>
        </li>
        <li className="nav-item">
          <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#free-tools" aria-controls="free-tools">
            <i className="fa fa-globe"></i>
            <span>Free Tools</span>
          </a>
          <div id="free-tools" className="collapse">
            <div className="bg-white py-2 collapse-inner rounded">
              <Link className="collapse-item" to="/free-tools/image">Image Tool</Link>
              <Link className="collapse-item" to="/free-tools/hindi">Hindi Writer</Link>
              <Link className="collapse-item" to="/free-tools/company">Edit Company</Link>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/logs">
            <i className="fa fa-user"></i>
            <span>Logs</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/users">
            <i className="fa fa-user"></i>
            <span>Users</span>
          </Link>
        </li>

      </ul>
    );
  };

  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <div>
      
      <Helmet>
        <title>{props.tabTitle?props.tabTitle:'Home'} - Nirwanji</title>
      </Helmet>

      <div>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <div className="float-left">
                  <LanguageField
                    //field={<button onClick={() => eval('setShowModal(true)')}>Search</button>}
                    heading='Search'
                    placeholder="Search"
                    onChange={(value: string) => {
                      setSearchQuery(value.trim())
                    }}
                  />
                  <Modal show={searchQuery!==''} onHide={() => setSearchQuery('')}>
                    <ModalHeader closeButton={true}>
                      <ModalTitle>Search Results</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                      Query : <strong>{searchQuery}</strong><br /><br />
                      Results will be here
                    </ModalBody>
                  </Modal>
                </div>
                <ul className="navbar-nav ml-auto">
                  <div className="topbar-divider d-none d-sm-block"></div>
                  <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small">{SessionUtil.get('firstname')}</span>
                      <img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" alt="Avatar" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                      <a className="dropdown-item" href="/">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                        Settings
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#" onClick={(e: any) => {
                        e.preventDefault();
                        SessionUtil.clear();
                        window.location.reload();
                      }}>
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </nav>
              <div className="container-fluid">
                <h1 className="h3 mb-4 text-gray-800">{props.heading}</h1>
                {props.content?props.content:(
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card shadow mb-4">
                        <div className="card-body" style={{padding:'0px'}}>
                          <img src="/assets/images/home.jpg" style={{width:'100%'}} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; Nirwanji 2021</span>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>

    </div>
  );

};
export default Dashboard;