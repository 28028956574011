import { useEffect, useState } from "react";
import EventNew from "../../components/event/new";
import HelpButton from "../../components/help";
import LanguageField from "../../components/language";
import AppAPI from "../../config/api";
import HttpUtil from "../../utils/http";
import Dashboard from "../dashboard";

const MakeEvent = () => {

  const Content = () => {

    const [categories, setCategories] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);
    const [modal, setModal] = useState<boolean>(false);

    useEffect(() => {
      fetchCategories();
      fetchItems();
    }, []);
  
    const fetchCategories = async () => {
      let res: any = await HttpUtil.getJson(AppAPI.category.fetch, {});
      if(res.data) {
        setCategories(res.data);
      }
    };
  
    const fetchItems = async () => {
      let res: any = await HttpUtil.getJson(AppAPI.ephemeris.items, {});
      if(res.data) {
        setItems(res.data);
      }
    };


    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Make Event
                <HelpButton
                  code='MAKE_EVENT'
                  heading='Make Event'
                />
              </h6>
            </div>
            <div className="card-body">
              <div className=" form-group">
                <div className="row">
                  <div className="col-lg-4">
                    <label>Category</label>
                    <select className="form-control my-field">
                      <option value=''>Select Category</option>
                      {categories.map((category: any, index: number) => {
                        return (
                          <option key={index} value={category.id}>{category.name}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-lg-4">
                    <label>Event Name</label>
                    <LanguageField
                      placeholder="Enter Event Name"
                      onChange={(value: string) => {

                      }}
                      />
                  </div>
                  <div className="col-lg-4 text-left">
                    <label>&nbsp;</label><br />
                    <button className="btn btn-primary my-btn" style={{marginTop:'-6px'}} onClick={() => setModal(true)}>
                      <i className="fa fa-plus" /> Event
                    </button>
                  </div>
                </div>                
              </div>

              {items.length>1?(
                <EventNew
                  items={items}
                  show={modal}
                  onClose={() => setModal(false)}
                />
              ):<></>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Dashboard content={<Content />} tabTitle={'Users'} />;

};
export default MakeEvent;