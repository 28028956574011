import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";

const ErrorPage404 = () => {

  let history = useHistory();
  
  return (
    <div>
      <Helmet bodyAttributes={{class: 'bg-gradient-primary'}}>
        <title>404</title>
      </Helmet>
      <div className="row justify-content-center">
        <div className="col-xl-3 col-lg-3 col-md-6">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-5">
                        Page Not Found<br /><br /><br />
                        <span style={{fontSize:'70px'}}>
                          <i className="fa fa-frown" />
                        </span>
                      </h1>
                      <div>
                        <a href="#" onClick={() => history.goBack()}>
                          <i className="fa fa-arrow-left" /> Back
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};
export default ErrorPage404;