import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppConfig from './config/app';
import AppRoutes from './config/routes';
import ErrorPage404 from './pages/error/404';
import LoginPage from './pages/login';
import { SessionUtil } from './utils/session';


const App = () => {

  SessionUtil.configure(AppConfig.SESSION.PREFIX, AppConfig.SESSION.PERSIST);

  const [routes, setRoutes] = useState<any[]>([]);

  useEffect(() => {
    let isLoggedIn = SessionUtil.get('id');
    setRoutes(AppRoutes.map((route: any, index: number) => {
      return {
        key: index,
        url: route.url,
        component: isLoggedIn?route.component:LoginPage
      };
    }));
  }, []);

  return (
    <div>
      <Switch>
        {routes.map((route: any, index: number) => {
          return (
            <Route exact key={index} path={route.url} component={route.component} />
          );
        })}
        <Route component={ErrorPage404} />
      </Switch>
    </div>
  );
};
export default App;
