import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Grid from "../../components/grid";
import HelpButton from "../../components/help";
import LanguageField from "../../components/language";
import AppAPI from "../../config/api";
import HttpUtil from "../../utils/http";
import Dashboard from "../dashboard";

const MakeItem = () => {

  const Content = () => {

    const [items, setItems] = useState<any[]>([]);
    const [status, setStatus] = useState<any>(<></>);
  
    useEffect(() => {
      fetch();
    }, []);
  
    const fetch = async () => {
      let res: any = await HttpUtil.getJson(AppAPI.make.itemFetch, {});
      if(res.data) {
        console.log(res.data);
        setItems(res.data);
      }
    };
  
    const save = async (e: any) => {
      if(HindiWriterResponse.text.trim()==='') {
        goStatus('danger', 'Please fill the value');
        return;
      }
      let formData: FormData = new FormData();
      formData.append('itemName', HindiWriterResponse.text);
      let res: any = await HttpUtil.postFormDataWithToken(AppAPI.make.itemSave, '', formData);
      if(res.meta) {
        if(res.meta.status===0) {
          goStatus('success', 'Item Saved');
          fetch();
        }
        else {
          goStatus('danger', res.meta.message);
        }
      }
      else {
        goStatus('danger', 'Could not login');
      }
    }
  
    const goStatus = (type: string, message: string, autoHide: boolean = true) => {
      setStatus(<Alert variant={type}>{message}</Alert>);
      if(autoHide) {
        setTimeout(() => {
          setStatus(<></>);
        }, 3000);
      }    
    }
  
    const HindiWriterResponse = {
      text: ''
    };


    const gridProps = {
      columnData: [
        {
          headerName: 'Item Name',
          field: 'name'
        }
      ],
      rowData: items
    };

    return (
      <div className="row">
        <div className="col-lg-3">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Make Items
                <HelpButton
                  code='MAKE_ITEM'
                  heading='Make Item'
                />
              </h6>
            </div>
            <div className="card-body">
                <div className="form-group">
                  <label>Item Name</label>
                  <LanguageField
                    heading='Item Name'
                    placeholder={'e.g Tithi, Nakshatra etc'}
                    onChange={(value: string) => {
                      console.log(value);
                      HindiWriterResponse.text = value;
                    }}
                  />
                </div>
                {status}
                <div className="form-group">
                  <button type="button" className="btn btn-primary my-btn" onClick={save}>
                    Save Item
                  </button>
                </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Items List</h6>
            </div>
            <div className="card-body">
              <Grid {...{...gridProps}} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Dashboard content={<Content />} tabTitle={'Make Item'} />;

};
export default MakeItem;