import { useEffect, useState } from "react";
import Grid from "../../components/grid";
import AppAPI from "../../config/api";
import HttpUtil from "../../utils/http";
import Dashboard from "../dashboard";


const ImportCache = () => {

  const Content = () => {

    const [data, setData] = useState<any[]>([]);
  
    useEffect(() => {
      fetch();
    }, []);
  
    const fetch = async () => {
      let res: any = await HttpUtil.getJson(AppAPI.ephemeris.modes, {});
      if(res.data) {
        setData(res.data);
      }
    };

    const gridProps = {
      columnData: [
        {
          headerName: 'Name',
          field: 'name',
          editable:false
        },
        {
          headerName: 'Number',
          field: 'num',
          editable:false
        },
        {
          headerName: 'Code',
          field: 'code',
          editable:false
        }
      ],
      rowData: data,
      columnConfig: {
        action: {
          width: 80,
          cellRenderer: (params: any) => {
            /*
              this.eGui = document.createElement('div');
              this.eGui.innerHTML = `
                <span>
                    <span class="my-value"></span>
                    <button class="btn-simple">Push For Total</button>
                </span>
              `;

              // get references to the elements we want
              this.eButton = this.eGui.querySelector('.btn-simple');
              this.eValue = this.eGui.querySelector('.my-value');

              // set value into cell
              this.cellValue = this.getValueToDisplay(params);
              this.eValue.innerHTML = this.cellValue;

              // add event listener to button
              this.eventListener = () => alert(`${this.cellValue} medals won!`);
              this.eButton.addEventListener('click', this.eventListener);
            */
            let cell: any = document.createElement('div');
            cell.innerHTML = `
              <input type="checkbox" class="eph-sid-mode-selection" /> &nbsp;
              <a class="btn btn-primary my-btn eph-sid-mode-btn disabled" href="/import/cache-gen?modeId=${params.data.id}" style="margin-top:-5px;">
                Go <i class="fa fa-arrow-right"></i>
              </a>
            `;
            let btn: any = cell.querySelector('.eph-sid-mode-btn');
            let selector: any = cell.querySelector('.eph-sid-mode-selection');
            selector.addEventListener('change', (e: any) => {
              let checked: boolean = e.target.checked;
              let btnClasses: string = btn.className;
              if(checked) {
                btnClasses = btnClasses.replace(' disabled', '');
              }
              else {
                btnClasses = btnClasses + ' disabled';
              }
              btn.className = btnClasses;
            });
            //return '<a class="btn btn-primary my-btn" href="/import/cache-mode?mode='+params.data.id+'" target="_blank">Go <i class="fa fa-arrow-right" /></a>'
            return cell;
          }
        }
      }
    };

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Configured - Ephemeris ( Sidereal Modes )</h6>
            </div>
            <div className="card-body">
              <Grid {...{...gridProps}} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Dashboard content={<Content />} tabTitle={'Import - Cache'} />;

};
export default ImportCache;