import Category from "../pages/category";
import Dashboard from "../pages/dashboard";
import ErrorPageDisabled from "../pages/error/disabled";
import Imports from "../pages/imports";
import ImportCache from "../pages/imports/cache";
import MakeEvent from "../pages/make/event";
import MakeItem from "../pages/make/item";
import Users from "../pages/users";

const AppRoutes = [
  {
    url: '/',
    component: Dashboard,
  },
  {
    url: '/dashboard',
    component: Dashboard,
  },
  {
    url: '/import',
    component: Imports,
  },
  {
    url: '/import/cache',
    component: ImportCache,
  },
  {
    url: '/import/cache-gen',
    component: ErrorPageDisabled,
  },
  {
    url: '/ephemeris/step-1',
    component: ErrorPageDisabled,
  },
  {
    url: '/ephemeris/step-2',
    component: ErrorPageDisabled,
  },
  {
    url: '/ephemeris/step-3',
    component: ErrorPageDisabled,
  },
  {
    url: '/item',
    component: MakeItem,
  },
  {
    url: '/make/categories',
    component: Category,
  },
  {
    url: '/make/event',
    component: MakeEvent,
  },
  {
    url: '/make/event/desc',
    component: ErrorPageDisabled,
  },
  {
    url: '/make/event/prediction',
    component: ErrorPageDisabled,
  },
  {
    url: '/template',
    component: ErrorPageDisabled,
  },
  {
    url: '/compile',
    component: ErrorPageDisabled,
  },
  {
    url: '/generate',
    component: ErrorPageDisabled,
  },
  {
    url: '/validate',
    component: ErrorPageDisabled,
  },
  {
    url: '/view',
    component: ErrorPageDisabled,
  },
  {
    url: '/compare',
    component: ErrorPageDisabled,
  },
  {
    url: '/logs',
    component: ErrorPageDisabled,
  },
  {
    url: '/users',
    component: Users,
  },
];
export default AppRoutes;