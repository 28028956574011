class HttpUtil {
  static getJson(url: string, request: any) {
    if(typeof(request)!=='undefined') url = url+'?'+(new URLSearchParams(request));
    return fetch(url, {
      method: 'get'
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
  static postJson(url: string, request: any) {
    return fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request)
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
  static postFormData(url: string, request: any) {
    return fetch(url, {
      method: 'post',
      body: request
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
  static postJsonWithToken(url: string, token: string | null | undefined, request: any) {
    return fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer '+token
      },
      body: JSON.stringify(request)
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
  static postFormDataWithToken(url: string, token: string, request: any) {
    console.log(url, token, request);
    return fetch(url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'bearer '+token,
      },
      body: request
    })
    .then((response) => response.json())
    .then((result) => Promise.resolve(result))
  }
}

export default HttpUtil;