const GridRendererAction = (params: any) => {
  let eGui = document.createElement("div");

  let editingCells = params.api.getEditingCells();

  let isCurrentRowEditing = editingCells.some((cell: any) => {
    return cell.rowIndex === params.node.rowIndex;
  });

  if (isCurrentRowEditing) {
    eGui.innerHTML = `
    <button class="action-button update btn btn-success my-btn" data-action="update" style="width:50px;">Update</button>
    <button class="action-button cancel btn btn-warning my-btn" data-action="cancel" style="width:50px;">Cancel</button>
    `;
  } else {
    eGui.innerHTML = `
    <button class="action-button edit btn btn-primary my-btn" data-action="edit" style="width:50px;">Edit</button>
    <button class="action-button delete btn btn-danger my-btn" data-action="delete" style="width:50px;">Delete</button>
    `;
  }      
  return eGui;
};
export default GridRendererAction;