import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useState } from "react";
import GridColumns from "./columns";

const Grid = (props: any) => {

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  const refreshGrid = () => {
    if(gridApi) {
      //gridColumnApi.autoSizeColumns();
      gridApi.refreshCells({force: true});
      gridApi.sizeColumnsToFit();
    }
  }

  const onCellClicked = (params: any) => {
    // Handle click event for action cells
    if (params.column.colId === "action" && params.event.target.dataset.action) {
      let action = params.event.target.dataset.action;

      if (action === "edit") {
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          // gets the first columnKey
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
        });
      }

      if (action === "delete") {
        params.api.applyTransaction({
          remove: [params.node.data]
        });
      }

      if (action === "update") {
        params.api.stopEditing(false);
      }

      if (action === "cancel") {
        params.api.stopEditing(true);
      }
    }
  }

  const onRowEditingStarted = (params: any) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  const onRowEditingStopped = (params: any) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  return (
    <div className="ag-theme-alpine" style={{ height: 600 }}>
      <AgGridReact
        onRowEditingStopped={onRowEditingStopped}
        onRowEditingStarted={onRowEditingStarted}
        onCellClicked={onCellClicked}
        editType="fullRow"
        suppressClickEdit={true}
        animateRows={true}
        pagination={true}
        paginationPageSize={14}
        defaultColDef={{
          sortable: true,
          editable: true,
          resizable: true,
          filter: true,
          floatingFilter: true
        }}
        columnDefs={GridColumns(props.columnData?props.columnData:[], props.columnConfig?props.columnConfig:{})}
        enableRangeSelection={true}
        onFirstDataRendered={refreshGrid}
        onGridReady={onGridReady}
        rowData={props.rowData?props.rowData:[]}
      />
    </div>
  );

};
export default Grid;