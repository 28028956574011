const AppConfig = {
  API: {
    URL: 'http://nirwanji.com:9001/api/v1'
    //URL: 'http://localhost:9001/api/v1'
  },
  SESSION: {
    PREFIX: 'nirwanji.admin.',
    PERSIST: true
  }
};
export default AppConfig;