import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Grid from "../../components/grid";
import HelpButton from "../../components/help";
import LanguageField from "../../components/language";
import AppAPI from "../../config/api";
import HttpUtil from "../../utils/http";
import Dashboard from "../dashboard";

const Users = () => {

  const Content = () => {

    const [data, setData] = useState<any[]>([]);
    const [status, setStatus] = useState<any>(<></>);

    const [inputFirstname, setInputFirstname] = useState<string>('');
    const [inputLastname, setInputLastname] = useState<string>('');
    const [inputEmail, setInputEmail] = useState<string>('');
    const [inputUserType, setInputUserType] = useState<string>('admin');
  
    useEffect(() => {
      fetch();
    }, []);
  
    const fetch = async () => {
      let res: any = await HttpUtil.getJson(AppAPI.user.fetch, {});
      if(res.data) {
        setData(res.data);
      }
    };
  
    const save = async (e: any) => {
      e.preventDefault();
      if(inputFirstname==='' || inputLastname==='' || inputEmail==='') {
        goStatus('danger', 'Please fill the value');
        return;
      }
      let res: any = await HttpUtil.postJson(AppAPI.user.save, {
        email: inputEmail,
        firstName: inputFirstname,
        lastName: inputLastname,
        userType: inputUserType
      });
      if(res.meta) {
        if(res.meta.status===0) {
          goStatus('success', 'Item Saved');
          fetch();
        }
        else {
          goStatus('danger', res.meta.message);
        }
      }
      else {
        goStatus('danger', 'Could not login');
      }
    }
  
    const goStatus = (type: string, message: string, autoHide: boolean = true) => {
      setStatus(<Alert variant={type}>{message}</Alert>);
      if(autoHide) {
        setTimeout(() => {
          setStatus(<></>);
        }, 3000);
      }    
    }


    const gridProps = {
      columnData: [
        {
          headerName: 'Username',
          field: 'username'
        },
        {
          headerName: 'Firstname',
          field: 'firstname'
        },
        {
          headerName: 'Lastname',
          field: 'lastname'
        },
        {
          headerName: 'User Type',
          field: 'priv'
        },
        {
          headerName: 'Created On',
          field: 'createdOn',
          editable:false
        }
      ],
      rowData: data
    };

    return (
      <div className="row">
        <div className="col-lg-3">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Make User
                <HelpButton
                  code='MAKE_USER'
                  heading='Make User'
                />
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={save}>
                <div className="form-group">
                  <label>Firstname</label>
                  <LanguageField
                    heading='Firstname'
                    placeholder={'Enter Firstname'}
                    onChange={(value: string) => setInputFirstname(value.trim())}
                  />
                </div>
                <div className="form-group">
                  <label>Lastname</label>
                  <LanguageField
                    heading='Lastname'
                    placeholder={'Enter Lastname'}
                    onChange={(value: string) => setInputLastname(value.trim())}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" className="form-control my-field" placeholder="Enter Email Address" onChange={(e: any) => setInputEmail(e.target.value.trim())} required />
                </div>
                <div className="form-group">
                  <label>User Type</label>
                  <select className="form-control my-field" onChange={(e: any) => setInputUserType(e.target.value.trim())}>
                    <option value="admin">admin</option>
                    <option value="user">user</option>
                  </select>
                </div>
                {status}
                <div className="form-group">
                  <button type="submit" className="btn btn-primary my-btn float-right" onClick={save}>
                    Submit <i className="fa fa-arrow-right" />
                    </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Users List</h6>
            </div>
            <div className="card-body">
              <Grid {...{...gridProps}} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Dashboard content={<Content />} tabTitle={'Users'} />;

};
export default Users;