import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

import convertLng from "../../lib/hindi/converter";

const LanguageField = (props: any) => {

  const [selected, setSelected] = useState<string>('HI');
  const [showModal, setShowModal] = useState<boolean>(false);

  const [inputText, setInputText] = useState<string>('');

  const [inputRoman, setInputRoman] = useState<string>('');
  const [inputLngHI, setInputLngHI] = useState<string>('');
  const [inputUnicode, setInputUnicode] = useState<string>('');

  const [inputLngEN, setInputLngEN] = useState<string>('');

  const reset = () => {
    setInputLngHI('');
    setInputRoman('');
    setInputUnicode('');
    setInputLngEN('');
  }

  useEffect(() => {
    if(props.field) {
      //props.field.onClick = setShowModal(true);
    }
  }, []);
  return (
    <div className={props.containerWidth?'float-left':''} style={{width:(props.containerWidth?props.containerWidth:'100%')}}>
      {props.field?props.field:(
        <input type="text" className="form-control my-field"
          value={inputText}
          placeholder={props.placeholder}
          onChange={(e: any) => {
            setInputText(e.target.value);
            if(props.onChange) props.onChange(e.target.value);
          }}
          onClick={() => setShowModal(true)}
          />
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <ModalHeader closeButton={true}>
          <ModalTitle>{props.heading}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="form-group text-center">
            Write in : &nbsp;
            <button type="button" className="btn btn-secondary my-btn"
              onClick={() => {
                setSelected('HI');
              }}
              style={{
                width:'55px', height:'24px',
                fontSize:'12px', fontWeight:'bold',
                marginTop:'-6px', marginLeft:'1px', marginRight:'1px',
                border:'0px', outline:'0px',
                borderTopRightRadius:'0px', borderBottomRightRadius:'0px',
                backgroundColor:(selected=='HI'?'#006f00':'')
              }}>हिन्दि</button>
            <button type="button" className="btn btn-secondary my-btn"
              onClick={() => {
                setSelected('EN');
              }}
              style={{
                width:'55px', height:'24px',
                fontSize:'12px', fontWeight:'bold',
                marginTop:'-6px', marginLeft:'0px',
                border:'0px', outline:'0px',
                borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px',
                backgroundColor:(selected=='EN'?'#006f00':'')
              }}>English</button>
          </div>
          {selected==='HI'?(
            <div>
              <div className="form-group">
                <label>Unicode</label>
                <textarea className="form-control my-field" disabled={true} value={inputUnicode} placeholder="Unicode Text"
                  style={{ backgroundColor: '#fff', height:'40px' }} />
              </div>
              <div className="form-group">
                <label>Hindi</label>
                <textarea className="form-control my-field" disabled={true} value={inputLngHI} placeholder="Hindi Text"
                  style={{ backgroundColor: '#fff', height:'40px' }} />
              </div>
              <div className="form-group">
                <label>Roman</label>
                <textarea className="form-control my-field" placeholder="Type in Roman" value={inputRoman} style={{height:'40px'}} onChange={(event: any) => {
                  let convertedValues = convertLng(event.target.value);
                  setInputRoman(convertedValues.roman);
                  setInputLngHI(convertedValues.lng);
                  setInputUnicode(convertedValues.unicode);
                }} />
              </div>
              <div className="form-group">
                <button type="button" className="btn btn-danger my-btn float-left" onClick={reset}>Reset</button>
                <button className="btn btn-primary my-btn float-right" onClick={() => {
                  let newValue = inputText + inputLngHI;
                  setInputText(newValue);
                  setShowModal(false);
                  reset();
                  if (props.onChange) props.onChange(newValue);
                }}>
                  Go <i className="fa fa-arrow-right" />
                </button>
              </div>
            </div>
          ):(
            <div>
              <div className="form-group">
                <label>English</label>
                <textarea className="form-control my-field" placeholder="Type in English" value={inputLngEN} onChange={(event: any) => {
                  setInputLngEN(event.target.value);
                }} />
              </div>
              <div className="form-group">
                <button type="button" className="btn btn-danger my-btn float-left" onClick={reset}>Reset</button>
                <button className="btn btn-primary my-btn float-right" onClick={() => {
                  let newValue = inputText + inputLngEN;
                  setInputText(newValue);
                  setShowModal(false);
                  reset();
                  if(props.onChange) props.onChange(newValue);
                }}>
                  Go <i className="fa fa-arrow-right" />
                </button>
              </div>
            </div>
          )}

        </ModalBody>
      </Modal>

    </div>
  );

};
export default LanguageField;