// This file is part of Indian Language Converter

export var vowels = "(A((O(M)?)|(o))?)|(En)|(H)|(I)|(M)|(TR)|(U)|(\\:)|(\\|(\\|)?)|(a((A)|(a)|(i)|(u))?)|(e(e)?)|(i)|(o(o)?)|(tR)|(u)"
export var consonants = "(B(h)?)|(Ch)|(D((dD)|(h))?)|(G)|(L(lL)?)|(N(nN)?)|(R(rR)?)|(Sh)|(T(h)?)|(Y)|(b(h)?)|(ch)|(d(h)?)|(f)|(g((G)|(h))?)|(h)|(j(h)?)|(k(h)?)|(l)|(m)|(n(Y)?)|(p(h)?)|(q(h)?)|(r)|(s(h)?)|(t(h)?)|(v)|(y)|(z)"
export var letter_codes = {
"~a" : "&#2309;",
"~aa" : "&#2310;",
"~A" : "&#2310;",
"~i" : "&#2311;",
"~ii" : "&#2312;",
"~I" : "&#2312;",
"~u" : "&#2313;",
"~oo" : "&#2314;",
"~U" : "&#2314;",
"~tR" : "&#2315;",
"~E" : "&#2317;",
"~e" : "&#2319;",
"~ai" : "&#2320;",
"~O" : "&#2321;",
"~o" : "&#2323;",
"~au" : "&#2324;",
"~TR" : "&#2400;",
"a" : "",
"aa" : "&#2366;",
"A" : "&#2366;",
"A" : "&#2366;",
"i" : "&#2367;",
"ii" : "&#2368;",
"I" : "&#2368;",
"u" : "&#2369;",
"uu" : "&#2370;",
"U" : "&#2370;",
"tR" : "&#2371;",
"TR" : "&#2372;",
"En" : "&#2373;",
"e" : "&#2375;",
"ai" : "&#2376;",
"Ao" : "&#2377;",
"o" : "&#2379;",
"au" : "&#2380;",
"k" : "&#2325;",
"c" : "&#2325;",
"kh" : "&#2326;",
"g" : "&#2327;",
"gh" : "&#2328;",
"ng" : "&#2329;",
"ch" : "&#2330;",
"chh" : "&#2331;",
"j" : "&#2332;",
"jh" : "&#2333;",
"ny" : "&#2334;",
"tt" : "&#2335;",
"tth" : "&#2336;",
"dd" : "&#2337;",
"ddh" : "&#2338;",
"N" : "&#2339;",
"t" : "&#2340;",
"th" : "&#2341;",
"d" : "&#2342;",
"dh" : "&#2343;",
"n" : "&#2344;",
"NaN" : "&#2345;",
"p" : "&#2346;",
"ph" : "&#2347;",
"b" : "&#2348;",
"bh" : "&#2349;",
"m" : "&#2350;",
"y" : "&#2351;",
"r" : "&#2352;",
"R" : "&#2353;",
"l" : "&#2354;",
"L" : "&#2355;",
"LL" : "&#2356;",
"v" : "&#2357;",
"w" : "&#2357;",
"sh" : "&#2358;",
"shh" : "&#2359;",
"ssh" : "&#2359;",
"s" : "&#2360;",
"h" : "&#2361;",
"q" : "&#2392;",
"KqK" : "&#2393;",
"GgG" : "&#2394;",
"Z" : "&#2395;",
"DdD" : "&#2396;",
"DhD" : "&#2397;",
"F" : "&#2398;",
"Y" : "&#2399;",
"AO" : "&#2305;",
"M" : "&#2306;",
"H" : "&#2307;",
":" : "&#2307;",
"aA" : "&#2365;",
"|" : "&#2404;",
"||" : "&#2405;",
"AOM" : "&#2384;",
"~AO" : "&#2305;",
"~M" : "&#2306;",
"~H" : "&#2307;",
"~:" : "&#2307;",
"~aA" : "&#2365;",
"~|" : "&#2404;",
"~||" : "&#2405;",
"~OM" : "&#2384;",
"*" : "&#2381;"
};