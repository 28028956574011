import { useState } from "react";

const EventField = (props: any) => {

  const [negative, setNegative] = useState<boolean>(false);
  const [data, setData] = useState<any>({});

  return (
    <div className="row mb-4">
      <div className="col-lg-1">
        <label>{props.heading}</label> &nbsp;<br />
        {negative?(
          <button className="btn btn-success my-btn" onClick={() => setNegative(false)}>
            <i className="fa fa-plus" />
          </button>
        ):(
          <button className="btn btn-danger my-btn" onClick={() => setNegative(true)}>
            <i className="fa fa-minus" />
          </button>
        )}
      </div>
      <div className="col-lg-11">
        {(props.items?props.items:[]).map((item: any, index: number) => {
          return negative?(
            <span key={props.step+'_n_'+index} className="tag tag-primary" style={{border:'1px solid #f00', background:'rgba(255, 0, 0, 0.05)'}}>
              <input type="checkbox" defaultChecked={data[props.step+'_n_'+item.val]?true:false} style={{ width: '10px' }} onChange={(e: any) => {
                let currData: any = {...data};
                if(e.target.checked) {
                  currData[props.step+'_n_'+item.val] = {...item, negative: true};
                }
                else {
                  delete currData[props.step+'_n_'+item.val];
                }
                setData(currData);
                if(props.onChange) props.onChange(Object.values(currData));
              }} />
              <span style={{ marginLeft: '5px' }}>{item.name}</span>
            </span>
          ):(
            <span key={props.step+'_p_'+index} className="tag tag-primary">
              <input type="checkbox" defaultChecked={data[props.step+'_p_'+item.val]?true:false} style={{ width: '10px' }} onChange={(e: any) => {
                let currData: any = {...data};
                if(e.target.checked) {
                  currData[props.step+'_p_'+item.val] = {...item, negative: false};
                }
                else {
                  delete currData[props.step+'_p_'+item.val];
                }
                setData(currData);
                if(props.onChange) props.onChange(Object.values(currData));
              }} />
              <span style={{ marginLeft: '5px' }}>{item.name}</span>
            </span>
          );
        })}
      </div>
    </div>
  );
};
export default EventField;