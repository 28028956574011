import { Link } from "react-router-dom";
import Dashboard from "../dashboard";

const Imports = () => {

  const Content = () => {

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Import</h6>
            </div>
            <div className="card-body">
            <Link className="btn btn-primary" to='/import/file'>File</Link> &nbsp; 
            <Link className="btn btn-primary" to='/import/cache'>Cache</Link> &nbsp; 
            <Link className="btn btn-primary" to='/import/other'>Other</Link> &nbsp; 
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Dashboard content={<Content />} tabTitle={'Import - Cache'} />;

};
export default Imports;