import AppConfig from "./app";

const AppAPI = {
  login: AppConfig.API.URL+'/user/login',
  ephemeris: {
    modes: AppConfig.API.URL+'/ephemeris/modes',
    items: AppConfig.API.URL+'/ephemeris/items',

  },
  make: {
    itemSave: AppConfig.API.URL+'/dashboard/make/item',
    itemFetch: AppConfig.API.URL+'/dashboard/make/item',

    itemPropFetch: AppConfig.API.URL+'/dashboard/make/item-prop',

    helpFetch: AppConfig.API.URL+'/dashboard/make/help',

  },
  user: {
    fetch: AppConfig.API.URL+'/user',
    save: AppConfig.API.URL+'/user',
  },
  help: {
    fetch: AppConfig.API.URL+'/help',
    save: AppConfig.API.URL+'/help',
  },
  category: {
    fetch: AppConfig.API.URL+'/category',
    save: AppConfig.API.URL+'/category',
  },
  logs: AppConfig.API.URL+'/logs',
};
export default AppAPI;