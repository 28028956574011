class SessionConfig {
  static prefix = 'app.';
  static persist = true;
}
export class SessionUtil {

  static set(sessions: any) {
    let storage = SessionConfig.persist?localStorage:sessionStorage;
    Object.keys(sessions).forEach((key: string) => {
      storage.setItem(SessionConfig.prefix+key, sessions[key]);
    });
  }

  static get(key: string) {
    let storage = SessionConfig.persist?localStorage:sessionStorage;
    console.log(SessionConfig.prefix+key);
    return storage.getItem(SessionConfig.prefix+key);
  }

  static configure(prefix: string, persist: boolean) {
    SessionConfig.prefix = prefix;
    SessionConfig.persist = persist
  }

  static clear() {
    let storage = SessionConfig.persist?localStorage:sessionStorage;
    storage.clear();
  }

}