import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Grid from "../../components/grid";
import HelpButton from "../../components/help";
import LanguageField from "../../components/language";
import AppAPI from "../../config/api";
import HttpUtil from "../../utils/http";
import Dashboard from "../dashboard";


const Category = () => {

  const Content = () => {

    const [data, setData] = useState<any[]>([]);
    const [status, setStatus] = useState<any>(<></>);

    const [inputName, setInputName] = useState<string>('');
    const [inputDesc, setInputDesc] = useState<string>('');
  
    useEffect(() => {
      fetch();
    }, []);
  
    const fetch = async () => {
      let res: any = await HttpUtil.getJson(AppAPI.category.fetch, {});
      if(res.data) {
        setData(res.data);
      }
    };
  
    const save = async (e: any) => {
      e.preventDefault();
      if(inputName==='' || inputDesc==='') {
        goStatus('danger', 'Please fill the value');
        return;
      }
      let res: any = await HttpUtil.postJson(AppAPI.category.save, {
        name: inputName,
        desc: inputDesc,
      });
      if(res.meta) {
        if(res.meta.status===0) {
          goStatus('success', 'Category Saved');
          fetch();
        }
        else {
          goStatus('danger', res.meta.message);
        }
      }
      else {
        goStatus('danger', 'Could not process request');
      }
    }
  
    const goStatus = (type: string, message: string, autoHide: boolean = true) => {
      setStatus(<Alert variant={type}>{message}</Alert>);
      if(autoHide) {
        setTimeout(() => {
          setStatus(<></>);
        }, 3000);
      }    
    }


    const gridProps = {
      columnData: [
        {
          headerName: 'Name',
          field: 'name'
        },
        {
          headerName: 'Description',
          field: 'desc'
        },
        {
          headerName: 'Created On',
          field: 'createdOn',
          editable:false
        }
      ],
      rowData: data
    };

    return (
      <div className="row">
        <div className="col-lg-3">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
                Make Category
                <HelpButton
                  code='MAKE_CATEGORY'
                  heading='Make Category'
                />
              </h6>
            </div>
            <div className="card-body">
              <form onSubmit={save}>
                <div className="form-group">
                  <label>Name</label>
                  <LanguageField
                    heading='Category Name'
                    placeholder={'Enter Name'}
                    onChange={(value: string) => setInputName(value.trim())}
                  />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <LanguageField
                    heading='Category Description'
                    placeholder={'Enter Description'}
                    onChange={(value: string) => setInputDesc(value.trim())}
                  />
                </div>
                {status}
                <div className="form-group">
                  <button type="submit" className="btn btn-primary my-btn float-right" onClick={save}>
                    Submit <i className="fa fa-arrow-right" />
                    </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Category List</h6>
            </div>
            <div className="card-body">
              <Grid {...{...gridProps}} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <Dashboard content={<Content />} tabTitle={'Category'} />;

};
export default Category;