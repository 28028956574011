import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import AppAPI from "../../config/api";
import HttpUtil from "../../utils/http";

const HelpButton = (props: any) => {

  const [code, setCode] = useState<string>(props.code);
  const [show, setShow] = useState<boolean>(false);
  const [editorShow, setEditorShow] = useState<boolean>(false);

  const [data, setData] = useState<any>({});

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    let res: any = await HttpUtil.getJson(AppAPI.help.fetch, {code: code});
    if(res.data) {
      setData(res.data);
    }
  };

  const save = async (id: number, content: string) => {
    let formData: any = new FormData();
    formData.append('id', id);
    formData.append('code', props.code);
    formData.append('heading', props.heading);
    formData.append('content', content);
    let res: any = await HttpUtil.postFormData(AppAPI.help.save, formData);
    if(res.data) {
      setData(res.data);
      setEditorShow(false);
    }
  }

  const Editor = (editorProps: any) => {

    useEffect(() => {
      console.log(data);
    });

    const [content, setContent] = useState<string>('');

    return (
      <Modal size="lg" show={editorProps.show} onHide={() => {
          if(editorProps.onCancel) editorProps.onCancel();
        }}>
        <ModalHeader closeButton={true}>
          <ModalTitle style={{fontSize:'14px', color:'#333'}}>Edit Help : <strong>{props.heading}</strong></ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label>Content</label>
            <textarea className="form-control my-field" placeholder="Enter Help Content"
              defaultValue={data.content?data.content:''}
              style={{height:'100px'}} onChange={(e: any) => setContent(e.target.value)}></textarea>
          </div>
          <div className="form-group">
            <div>
              <button className="btn btn-success my-btn float-right" onClick={() => {
                save(data.id?data.id:0, content);
              }}>Submit</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const editorProps = {
    show: editorShow,
    onCancel: () => setEditorShow(false),
  };

  return (
    <span>
      <button className="btn my-btn" style={{padding:'0px', fontSize:'17px', marginLeft:'10px', color:'#1caf9a'}} onClick={() => {
        setShow(true);
      }}>
        <i className="fa fa-question-circle" />      
      </button>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <ModalHeader closeButton={true}>
          <ModalTitle style={{fontSize:'14px', color:'#333'}}>Help : <strong>{props.heading}</strong></ModalTitle>
        </ModalHeader>
        <ModalBody>
          {data.content?(
            <div className="form-group">
              {data.content}
            </div>
          ):<>No Help Content</>}
          <div className="form-group">
            <button className="btn btn-primary my-btn float-right" onClick={() => setEditorShow(true)}>Edit</button>
            <Editor {...{...editorProps}} />
          </div>
        </ModalBody>
      </Modal>
    </span>
  );

};
export default HelpButton;